import { EpicTypes } from '../EpicTypes';
import { switchMap, catchError, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { GetObservableFactory } from '../../services/http/GetObservableFactory';
import { PostObservableFactory } from '../../services/http/PostObservableFactory';
import {
  fetchUserStockData,
  assignUserStocksDashData, assignUserWarrantData,
  assignUserWarrantHistory, assignUserStockHistory,
  assignUserStocksRecentActions,
  fetchAllUserWarrantData,
  assignBrokageData,
} from './userStocksActions';
//fetchAllUserWarrantData
import { setIsLoading } from '../../utils/websiteUtil'
//import { merge, of, concat, throwError } from 'rxjs'
import { merge, of, throwError } from 'rxjs'
//import { BustCache, ResetCache } from '../ActionTypes';
//import { updateCache } from "../website/websiteActions";
//import rClone from 'ramda/src/clone'

import { appStore } from '../../index';

export const userStocksEpic = (action$, state) => {
  return merge(
    //////////////////////////////////////////////////////
    // Stock Actions
    /////////////////////////////////////////////////////
    action$.pipe(
      ofType(EpicTypes.FETCH_USER_STOCKS_DATA),
      switchMap(() => {
        //if (BustCache(EpicTypes.FETCH_USER_STOCKS_DATA)) {
          setIsLoading(EpicTypes.FETCH_USER_STOCKS_DATA, true);
          return GetObservableFactory.getUserStock$()
            .pipe(
              map(res => {
                setIsLoading(EpicTypes.FETCH_USER_STOCKS_DATA, false);
                if (res && Array.isArray(res)) {
                  // success case
                  //appStore.dispatch(updateCache(EpicTypes.FETCH_USER_STOCKS_DATA)); //Update the cahe
                  appStore.dispatch(assignUserStocksDashData(res));
                }
                return { type: `empty` };
              }),
              catchError((err) => {
                setIsLoading(EpicTypes.FETCH_USER_STOCKS_DATA, false);
                const errMsg = `${EpicTypes.FETCH_USER_STOCKS_DATA}: ${err}`
                console.error(errMsg)
                //addToast(errMsg, { appearance: 'error' });
                //if (err.status === 401) return of(assignLoggedIn(false))
                return throwError(err);
              }),
            )  // getByUrl$().pipe
        /*} else {
          return [];
        }*/
      })  // switchMap
    ), //action$.pipe*/

    action$.pipe(
      ofType(EpicTypes.FETCH_BROKAGE_DATA),
      switchMap(() => {
          setIsLoading(EpicTypes.FETCH_BROKAGE_DATA, true);
          return GetObservableFactory.getBrokageData$()
            .pipe(
              map(res => {
                setIsLoading(EpicTypes.FETCH_BROKAGE_DATA, false);
                if (res && Array.isArray(res)) {
                  appStore.dispatch(assignBrokageData(res));
                }
                return { type: `empty` };
              }),
              catchError((err) => {
                setIsLoading(EpicTypes.FETCH_BROKAGE_DATA, false);
                const errMsg = `${EpicTypes.FETCH_BROKAGE_DATA}: ${err}`
                console.error(errMsg)
                //addToast(errMsg, { appearance: 'error' });
                //if (err.status === 401) return of(assignLoggedIn(false))
                return throwError(err);
              }),
            )  // getByUrl$().pipe
        /*} else {
          return [];
        }*/
      })  // switchMap
    ), //action$.pipe*/
    

    action$.pipe(
      ofType(EpicTypes.FETCH_USER_STOCKS_ACTIONS),
      switchMap(({ payload }) => {
        //if (BustCache(EpicTypes.FETCH_USER_STOCKS_ACTIONS)) {
          setIsLoading(EpicTypes.FETCH_USER_STOCKS_ACTIONS, true)
          appStore.dispatch(assignUserStocksRecentActions([])) // response is empty
          //return GetObservableFactory.getUserStockActions$()
          return PostObservableFactory.postUserStockActions$(payload)
            .pipe(
              map(res => {
                setIsLoading(EpicTypes.FETCH_USER_STOCKS_ACTIONS, false)
                if (res?.response && Array.isArray(res.response)) {
                  // success case
                  //appStore.dispatch(updateCache(EpicTypes.FETCH_USER_STOCKS_ACTIONS)); //Update the cahe
                  appStore.dispatch(assignUserStocksRecentActions(res.response));
                }
                return { type: `empty` };
              }),
              catchError((err) => {
                setIsLoading(EpicTypes.FETCH_USER_STOCKS_ACTIONS, false)
                const errMsg = `${EpicTypes.FETCH_USER_STOCKS_ACTIONS}: ${err}`
                console.error(errMsg)
                //addToast(errMsg, { appearance: 'error' });
                //if (err.status === 401) return of(assignLoggedIn(false))
                return throwError(err);
              }),
            )  // getByUrl$().pipe
        /*} else {
          return [];
        }*/
      })  // switchMap
    ), //action$.pipe*/
    action$.pipe(
      ofType(EpicTypes.FETCH_USER_STOCKS_HISTORY_DATA),
      switchMap(({ code, market }) => {
        ///////////////////// get the user stock history for one stock /////////////////////
        setIsLoading(EpicTypes.FETCH_USER_STOCKS_HISTORY_DATA, true)
        appStore.dispatch(assignUserStockHistory([]));
        return GetObservableFactory.getUserStockHistoryFor$(code, market)
          .pipe(
            switchMap(res => {
              setIsLoading(EpicTypes.FETCH_USER_STOCKS_HISTORY_DATA, false)
              if (!res) {
                const errMsg = `Failed to download user stock history for ` + code + `, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              } else {
                if (res && Array.isArray(res)) {
                  //appStore.dispatch(updateCache(EpicTypes.FETCH_USER_STOCKS_HISTORY_DATA)); //Update the cahe
                  appStore.dispatch(assignUserStockHistory(res));
                }
              }
              return merge(of({ type: `empty` }));
            }),
            catchError((err) => {
              setIsLoading(EpicTypes.FETCH_USER_STOCKS_HISTORY_DATA, false)
              console.error(`[ERROR - ${EpicTypes.FETCH_USER_STOCKS_HISTORY_DATA}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.POST_USER_STOCKS_REMOVE_STOCK),
      switchMap(({ payload, callBack }) => {
        return PostObservableFactory.postDeleteUserStock$(payload)
          .pipe(
            map(res => {
              if (res.status !== 200) {
                const errMsg = `Failed to remove stock, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              }
              if (callBack) callBack();
              //ResetCache(EpicTypes.FETCH_USER_STOCKS_ACTIONS);
              //ResetCache(EpicTypes.FETCH_USER_STOCKS_DATA);
              return fetchUserStockData();
            }),
            catchError((err) => {
              setIsLoading(EpicTypes.FETCH_USER_STOCKS_DATA, false);
              console.error(`[ERROR - ${EpicTypes.POST_USER_STOCKS_REMOVE_STOCK}]: ${err}`)
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.POST_USER_STOCKS_ADD_STOCK),
      switchMap(({ payload, callBack }) => {
        setIsLoading(EpicTypes.FETCH_USER_STOCKS_DATA, true);
        return PostObservableFactory.postAddUserStock$(payload)
          .pipe(
            map(res => {
              if (res.status !== 200) {
                const errMsg = `Failed to add stock, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              }
              if (callBack) callBack();
              //ResetCache(EpicTypes.FETCH_USER_STOCKS_ACTIONS);
              //ResetCache(EpicTypes.FETCH_USER_STOCKS_DATA);
              return fetchUserStockData();
            }),
            catchError((err) => {
              setIsLoading(EpicTypes.FETCH_USER_STOCKS_DATA, false);
              console.error(`[ERROR - ${EpicTypes.POST_USER_STOCKS_ADD_STOCK}]: ${err}`)
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.POST_USER_STOCKS_EDIT_STOCK),
      switchMap(({ payload, callBack }) => {
        setIsLoading(EpicTypes.FETCH_USER_STOCKS_DATA, true);
        return PostObservableFactory.postEditUserStock$(payload)
          .pipe(
            map(res => {
              if (res.status !== 200) {
                const errMsg = `Failed to update stock, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              }
              if (callBack) callBack();
              //ResetCache(EpicTypes.FETCH_USER_STOCKS_ACTIONS);
              //ResetCache(EpicTypes.FETCH_USER_STOCKS_DATA);
              return fetchUserStockData();
            }),
            catchError((err) => {
              setIsLoading(EpicTypes.FETCH_USER_STOCKS_DATA, false);
              console.error(`[ERROR - ${EpicTypes.POST_USER_STOCKS_EDIT_STOCK}]: ${err}`)
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
    //////////////////////////////////////////////////////////////////
    // Warrant actions
    //////////////////////////////////////////////////////////////////
    action$.pipe(
      ofType(EpicTypes.FETCH_ALL_USER_WARRANT_DATA),
      switchMap(() => {
        //if (BustCache(EpicTypes.FETCH_ALL_USER_WARRANT_DATA)) {
          setIsLoading(EpicTypes.FETCH_ALL_USER_WARRANT_DATA, true)
          return GetObservableFactory.getUserWarrants$()
            .pipe(
              map(res => {
                setIsLoading(EpicTypes.FETCH_ALL_USER_WARRANT_DATA, false)
                if (res && Array.isArray(res)) {
                  // success case
                 // appStore.dispatch(updateCache(EpicTypes.FETCH_ALL_USER_WARRANT_DATA)); //Update the cahe
                  appStore.dispatch(assignUserWarrantData(res));
                }
                return { type: `empty` };
              }),
              catchError((err) => {
                setIsLoading(EpicTypes.FETCH_ALL_USER_WARRANT_DATA, false)
                const errMsg = `${EpicTypes.FETCH_ALL_USER_WARRANT_DATA}: ${err}`
                console.error(errMsg)
                //addToast(errMsg, { appearance: 'error' });
                //if (err.status === 401) return of(assignLoggedIn(false))
                return throwError(err);
              }),
            )  // getByUrl$().pipe
       /* } else {
          return [];
        }*/
      })  // switchMap
    ), //action$.pipe*/
    action$.pipe(
      ofType(EpicTypes.FETCH_WARRANT_HISTORY_DATA_FOR),
      switchMap(({ code, market }) => {
        setIsLoading(EpicTypes.FETCH_WARRANT_HISTORY_DATA_FOR, true);
        return GetObservableFactory.getUserWarrantsHistoryFor$(code, market)
          .pipe(
            map(res => {
              appStore.dispatch(assignUserWarrantHistory([]));
              setIsLoading(EpicTypes.FETCH_WARRANT_HISTORY_DATA_FOR, false);
              if (res && Array.isArray(res)) {
                // success case
                appStore.dispatch(assignUserWarrantHistory(res));
              }
              return { type: `empty` };
            }),
            catchError((err) => {
              setIsLoading(EpicTypes.FETCH_WARRANT_HISTORY_DATA_FOR, false);
              const errMsg = `${EpicTypes.FETCH_WARRANT_HISTORY_DATA_FOR}: ${err}`
              console.error(errMsg)
              //addToast(errMsg, { appearance: 'error' });
              //if (err.status === 401) return of(assignLoggedIn(false))
              return throwError(err);
            }),
          )  // getByUrl$().pipe

      })  // switchMap
    ), //action$.pipe*/
    action$.pipe(
      ofType(EpicTypes.POST_USER_WARRANT_ADD),
      switchMap(({ payload, callBack }) => {
        return PostObservableFactory.postAddWarrant$(payload)
          .pipe(
            map(res => {
              if (res.status !== 200) {
                const errMsg = `Failed to add new warrant, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              }
              //ResetCache(EpicTypes.FETCH_ALL_USER_WARRANT_DATA);
              if (callBack) callBack();
              return fetchAllUserWarrantData();
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.POST_USER_WARRANT_ADD}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.POST_USER_WARRANT_EDIT),
      switchMap(({ payload, callBack }) => {
        return PostObservableFactory.postEditWarrant$(payload)
          .pipe(
            map(res => {
              if (res.status !== 200) {
                const errMsg = `Failed to edit / update warrant, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              }
              if (callBack) callBack('');
              return fetchAllUserWarrantData();
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.POST_USER_WARRANT_EDIT}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.POST_USER_WARRANT_EXCUTE_BUY),
      switchMap(({ payload, callBack }) => {
        return PostObservableFactory.postExcuteWarrantBuy$(payload)
          .pipe(
            map(res => {
              if (res.status !== 200) {
                const errMsg = `Failed to excute warrant, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              }
              //ResetCache([EpicTypes.FETCH_USER_STOCKS_ACTIONS,
              //EpicTypes.FETCH_USER_STOCKS_DATA,
              //EpicTypes.FETCH_ALL_USER_WARRANT_DATA]);
              if (callBack) callBack();
              return fetchUserStockData();
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.POST_USER_WARRANT_EXCUTE_BUY}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe
    action$.pipe(
      ofType(EpicTypes.POST_USER_STOCKS_DIVIDEND_UPDATE),
      switchMap(({ payload, callBack }) => {
        return PostObservableFactory.postUserCalcDividend$(payload)
          .pipe(
            map(res => {
              if (res.status !== 200) {
                const errMsg = `Failed to update the dividen status, status: ${res.status}`
                console.error(errMsg)
                //notify(errMsg, 'error', 5000)
              }
              if (callBack) callBack();
              return { type: `empty` };
            }),
            catchError((err) => {
              console.error(`[ERROR - ${EpicTypes.POST_USER_STOCKS_DIVIDEND_UPDATE}]: ${err}`)
              // withRouter(({history})=>setTimeout(history.push('/home'),2000))
              return { type: `empty` };
            }),
          )  // getByUrl$().pipe
      })  // switchMap
    ), //action$.pipe

  ) // merge()
}
