import { ajax } from 'rxjs/ajax';
import { throwError } from 'rxjs';
import { timeout, catchError, retry, map } from 'rxjs/operators';
import { UrlStringBuilder } from './UrlStringBuilder'

export class GetObservableFactory {

   static getAfterLoginHeader(kiwifireSessionId) {
      return {
         'kiwifireSessionId': kiwifireSessionId,
      }
   }

   static getFromAPI$(url, timeOut = 30000, retryTimes = 3) {
      //const login = appStore.getState().login
      //const currentLoginSession = login ? login.currentLoginSession : login
      //const afterLoginSessionId = currentLoginSession ? currentLoginSession.afterLoginSessionId : ''
      return ajax.getJSON(
         url 
         // ,GetObservableFactory.getAfterLoginHeader(afterLoginSessionId)
      ).pipe(
         timeout(timeOut),
         retry(retryTimes),
         map((response) => {
            return response
         }),
         catchError(error => {
            if (error.status === 500) {
               console.error(error)
            }
            return throwError(error)
         }),
      )
   }

   static getUser$() {
      return GetObservableFactory
         .getAfterLogin$(
            UrlStringBuilder.getUserUrl()
         )
   }

   
   static getByUrl$(url) {
      return ajax.getJSON(
         url
         // ,GetObservableFactory.getAfterLoginHeader(afterLoginSessionId)
      ).pipe(
         timeout(30000),
         retry(3),
         map((response) => {
            return response
         }),
         catchError(error => {
            if (error.status === 500) {
               console.error(error)
            }
            return throwError(error)
         }),
      )
   }

//////////////////////////////////////////////////////
//
//  Server calls
//
/////////////////////////////////////////////////////
   static getIsauthenticated$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getIsauthenticatedUrl()
      )
   }

   static getUserAccountDetails$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserAccountDetailsUrl()
      )
   }
   static getUserAccountPortfolios$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserAccountPortfoliosUrl()
      )
   }

   static callLogout$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.callLogoutUrl()
      )
   }
   static getUserUpcomingNZDivi$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserUpcomingNZDiviUrl()
      )
   }
   static getUserUpcomingASDivi$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserUpcomingASDiviUrl()
      )
   }
   static getAllStockNames$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getAllStockNamesUrl()
      )
   }
   static getGlossary$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getGlossaryUrl()
      )
   }
   static getUserStock$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserStockUrl()
      )
   }
   static getBrokageData$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getBrokageDataUrl()
      )
   }
   
   //////////////////////////////////////
   //
   // Stocks Warrents calls
   //
   ////////////////////////////////////
   static getUserWarrants$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserWarrantsUrl()
      )
   }
   static getUserWarrantsHistoryFor$(code,market){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserWarrantsHistoryForUrl(code,market)
      )
   }
   //////////////////////////////////////
   static getUserPortfolios$(){ //[TODO] - this function does not show up in search
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserPortfoliosUrl()
      )
   }
   static getNZDiviCalendarCodesDates$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getNZDiviCalendarCodesDatesUrl()
      )
   }
   static getASDiviCalendarCodesDates$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getASDiviCalendarCodesDatesUrl()
      )
   }

   static getOneDayNZDividends$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getOneDayNZDividendsUrl()
      )
   }
   static getOneDayASDividends$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getOneDayASXDividendsUrl()
      )
   }
   static getYoutubeInfo$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getYoutubeInfoUrl()
      )
   }
   static getProducts$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getProductsUrl()
      )
   }
   static getUpcomingDividendsNZ$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUpcomingDividendsNZUrl()
      )
   }
   static getUpcomingDividendsAs$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUpcomingDividendsAsUrl()
      )
   }
   static getUserList$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserListUrl()
      )
   }
   static getUserStockActions$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserStockActionsUrl()
      )
   }
   static getUserGoals$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserGoalsUrl()
      )
   }
   static getUserCrypto$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserCryptoUrl()
      )
   }
   static getCryptoMetaData$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getCryptoMetaDataUrl()
      )
   }
   static getCryptoQuoteData$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getCryptoQuoteDataUrl()
      )
   }
   static getDiviHistoryFor$(code,market){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getDiviHistoryForUrl(code,market)
      )
   }
   static getFullStockHistoryFor$(code,market){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getFullStockHistoryForUrl(code,market)
      )
   }
   static getDiviHistoryValueFor$(code,market){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getDiviHistoryValueForUrl(code,market)
      )
   }
   static getDiviHistoryValueByYearsAgo$(code,market){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getDiviHistoryValueForUrl(code,market)
      )
   }

   static getUserDetails$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserDetailsUrl()
      )
   }
   static getUserStockHistoryFor$(code,market){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getUserStockHistoryForUrl(code,market)
      )
   }

   static getTimeFrames$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getTimeFramesUrl()
      )
   }
   static getCountoryCodes$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getCountoryCodesUrl()
      )
   }
   static getOneDayNZPrice$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getOneDayNZPriceUrl()
      )
   }
   static getOneDayASXDividends$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getOneDayASXDividendsUrl()
      )
   }
   static getOneDayASXPrice$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getOneDayASXPriceUrl()
      )
   }
   static getStockAnnouncements$(code, market){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getStockAnnouncementsUrl(code, market)
      )
   }
   static getNzxLatestPrice$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getNzxLatestPriceUrl()
      )
   }
   static getAsxLatestPrice$(){
      return GetObservableFactory
      .getFromAPI$(
         UrlStringBuilder.getAsxLatestPriceUrl()
      )
   }
  
}