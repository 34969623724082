import { appStore } from '../index';
import { EpicTypes } from './EpicTypes';
import { resetCache } from '../store/website/websiteActions';
/////////////////////////////////////////////////////
// BustCache: returns true if this is the first 
//            time or the cache is older then 1 hour
// @Param cacheStore : name of the store
////////////////////////////////////////////////////
export const BustCache = (key) => {
   const state = appStore.getState();
   if (!state || !state['website'] || !state['website']['cacheDateTime'][key]) {
      return true;
   }
   let result = CheckCache(state['website']['cacheDateTime'][key]);
   return result.update;
}
export function ResetCache(key) {
   appStore.dispatch(resetCache(key));
}
//resets the cache date time for all the user calls
export function ResetUserCache() {
   appStore.dispatch(resetCache([
      EpicTypes.FETCH_USER_SETTINGS_SIGN_IN,
      EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_DATA,
      EpicTypes.FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS,
      EpicTypes.FETCH_DIVIDEND_USER_DATA,
      EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_NZ,
      EpicTypes.FETCH_DIVIDEND_USER_UP_COMMING_AS,
      EpicTypes.FETCH_USER_STOCKS_DATA,
      EpicTypes.FETCH_USER_STOCKS_ACTIONS,
      EpicTypes.FETCH_USER_STOCKS_HISTORY_DATA,
      EpicTypes.FETCH_ALL_USER_WARRANT_DATA,
      EpicTypes.FETCH_GOAL_USER_LIST
   ]));
}
export const CheckCache = (dateTime) => {
   var oldTime = dateTime;
   if (typeof dateTime === 'string') {
      oldTime = new Date(dateTime);
   }
   var newDateTime = new Date();
   newDateTime.setHours(newDateTime.getHours() + 1);
   //newDateTime.setMinutes(newDateTime.getMinutes() + 2);
   if (!dateTime) { //first time
      return {
         update: true,
         dateTime: newDateTime,
      }
   } else {
      var toDay = new Date();
      if (oldTime < toDay) {
         return {
            update: true,
            dateTime: newDateTime,
         }
      } else {
         return {
            update: false,
            dateTime: oldTime,
         }
      }
   }
}
export const ActionTypes = {
   // App Config
   ASSIGN_SERVER_CONFIG: 'ASSIGN_SERVER_CONFIG',
   ASSIGN_USER_DEFAULT: 'ASSIGN_USER_DEFAULT',
   // UI
   START_LOADING: 'START_LOADING',
   STOP_LOADING: 'STOP_LOADING',
   SHOW_SIDE_NAV_BAR: 'SHOW_SIDE_NAV_BAR',
   HIDE_SIDE_NAV_BAR: 'HIDE_SIDE_NAV_BAR',

   /// Calculations
   ASSIGN_CALCULATION_STOCK_CODE_SELECTED: 'ASSIGN_CALCULATION_STOCK_CODE_SELECTED',
   ASSIGN_CALCULATION_CALCULATION_MARKET_SELECTED: 'ASSIGN_CALCULATION_MARKET_SELECTED',
   ASSIGN_CALCULATION_CALCULATION_DIVIDENS_SELECTED: 'ASSIGN_CALCULATION_DIVIDENS_SELECTED',

   // crypto
   ASSIGN_CRYPTO_LIST: 'ASSIGN_CRYPTO_LIST',
   ASSIGN_CRYPTO_META_DATA: 'ASSIGN_CRYPTO_META_DATA',
   ASSIGN_CRYPTO_USER: 'ASSIGN_CRYPTO_USER',
   ASSIGN_CRYPTO_CURRENT_PRICE: 'ASSIGN_CRYPTO_CURRENT_PRICE',

   //Dividends
   ASSIGN_DIVIDEND_NZX_LASEST_DIVI: 'ASSIGN_DIVIDEND_NZX_LASEST_DIVI',
   ASSIGN_DIVIDEND_ASX_LASEST_DIVI: 'ASSIGN_DIVIDEND_ASX_LASEST_DIVI',
   ASSIGN_DIVIDEND_USER_YEARLY_RETURN: 'ASSIGN_DIVIDEND_USER_YEARLY_RETURN',
   ASSIGN_DIVIDEND_USER_INVESTED_RETURN: 'ASSIGN_DIVIDEND_USER_INVESTED_RETURN',
   ASSIGN_DIVIDEND_USER_YEARLY_NZ_RETURN: 'ASSIGN_DIVIDEND_USER_YEARLY_NZ_RETURN',
   ASSIGN_DIVIDEND_USER_YEARLY_AU_RETURN: 'ASSIGN_DIVIDEND_USER_YEARLY_AU_RETURN',
   ASSIGN_DIVIDEND_SINGLE_HISTORY: 'ASSIGN_DIVIDEND_SINGLE_HISTORY',
   ASSIGN_DIVIDEND_SINGLE_VALUE_HISTORY: 'ASSIGN_DIVIDEND_SINGLE_VALUE_HISTORY',
   ASSIGN_DIVIDEND_AS_CALENDAR_DATA: 'ASSIGN_DIVIDEND_AS_CALENDAR_DATA',
   ASSIGN_DIVIDEND_NZ_CALENDAR_DATA: 'ASSIGN_DIVIDEND_NZ_CALENDAR_DATA',
   ASSIGN_DIVIDEND_UP_COMING_AS: 'ASSIGN_DIVIDEND_UP_COMING_AS',
   ASSIGN_DIVIDEND_UP_COMING_NZ: 'ASSIGN_DIVIDEND_UP_COMING_NZ',
   ASSIGN_DIVIDEND_UP_COMING_USER_NZ: 'ASSIGN_DIVIDEND_UP_COMING_USER_NZ',
   ASSIGN_DIVIDEND_UP_COMING_USER_AS: 'ASSIGN_DIVIDEND_UP_COMING_USER_AS',

   //Goals
   ASSIGN_GOAL_LIST: 'ASSIGN_GOAL_LIST',
   ASSIGN_GOAL_NEXT_NAME: 'ASSIGN_GOAL_NEXT_NAME',
   ASSIGN_GOAL_NEXT_TOGO: 'ASSIGN_GOAL_NEXT_TOGO',

   //Reports
   ASSIGN_REPORT_STOCK_SELECTED: 'ASSIGN_REPORT_STOCK_SELECTED',
   ASSIGN_REPORT_USER_HISTORY_FOR_SELECTED_STOCK: 'ASSIGN_REPORT_USER_HISTORY_FOR_SELECTED_STOCK',

   //User setting
   ASSIGN_USER_ACCOUNT_SETTINGS_DATA: 'ASSIGN_USER_ACCOUNT_SETTINGS_DATA',
   ASSIGN_USER_PORTFOLIO_ID: 'ASSIGN_USER_PORTFOLIO_ID',
   ASSIGN_USER_PORTFOLIO_COLOR: 'ASSIGN_USER_PORTFOLIO_COLOR',
   ASSIGN_USER_PORTFOLIO_SETTINGS_DATA: 'ASSIGN_USER_PORTFOLIO_SETTINGS_DATA',
   ASSIGN_USER_PORTFOLIOS_LIST: 'ASSIGN_USER_PORTFOLIOS_LIST',
   ASSIGN_USER_NOTIFICATIONS_DATA: 'ASSIGN_USER_NOTIFICATIONS_DATA',

   //userStocks
   ASSIGN_USER_STOCKS_NOTIFCATIONS: 'ASSIGN_USER_STOCKS_NOTIFCATIONS',
   ASSIGN_USER_STOCKS_RECENT_ACTIONS_DATA: 'ASSIGN_USER_STOCKS_RECENT_ACTIONS_DATA',
   ASSIGN_USER_STOCKS_VALUE_DATA: 'ASSIGN_USER_STOCKS_VALUE_DATA',
   ASSIGN_USER_STOCKS_INVESTED_VALUE_DATA: 'ASSIGN_USER_STOCKS_INVESTED_VALUE_DATA',
   ASSIGN_USER_STOCK_HISORY_DATA: 'ASSIGN_USER_STOCK_HISORY_DATA',
   ASSIGN_USER_NZ_STOCKS_VALUE_DATA: 'ASSIGN_USER_NZ_STOCKS_VALUE_DATA',
   ASSIGN_USER_AU_STOCKS_VALUE_DATA: 'ASSIGN_USER_AU_STOCKS_VALUE_DATA',
   ASSIGN_USER_DASH_STOCKS_DATA: 'ASSIGN_USER_DASH_STOCKS_DATA',
   ASSIGN_USER_NZ_STOCKS_DATA: 'ASSIGN_USER_NZ_STOCKS_DATA',
   ASSIGN_USER_AS_STOCKS_DATA: 'ASSIGN_USER_AS_STOCKS_DATA',
   ASSIGN_USER_WARRANT_DATA: 'ASSIGN_USER_WARRANT_DATA',
   ASSIGN_USER_WARRANT_HISORY_DATA: 'ASSIGN_USER_WARRANT_HISORY_DATA',
   ASSIGN_BROKAGE_DATA:'ASSIGN_BROKAGE_DATA',

   //Website and gloabl
   ASSIGN_WEBSITE_YOUTUBE_CONTEN: 'ASSIGN_WEBSITE_YOUTUBE_CONTEN',
   ASSIGN_WEBSITE_TITLE: 'ASSIGN_WEBSITE_TITLE',
   ASSIGN_WEBSITE_SET_ALL_STOCK_CODE_NAME_MARKET: 'ASSIGN_WEBSITE_SET_ALL_STOCK_CODE_NAME_MARKET',
   ASSIGN_WEBSITE_IS_AUTHENTICATED: 'ASSIGN_WEBSITE_IS_AUTHENTICATED',
   ASSIGN_WEBSITE_TIME_FRAMES: 'ASSIGN_WEBSITE_TIME_FRAMES',
   ASSIGN_WEBSITE_COUNTORY_CODES: 'ASSIGN_WEBSITE_COUNTORY_CODES',
   ASSIGN_WEBSITE_NZX_LATEST_PRICE: 'ASSIGN_WEBSITE_NZX_LATEST_PRICE',
   ASSIGN_WEBSITE_ASX_LATEST_PRICE: 'ASSIGN_WEBSITE_ASX_LATEST_PRICE',
   ASSIGN_WEBSITE_SET_STOCK_ANNOUNCEMENTS: 'ASSIGN_WEBSITE_SET_STOCK_ANNOUNCEMENTS',

   // root
   ASSIGN_IS_LOADING: 'ASSIGN_IS_LOADING',
   RESET_STORE: 'RESET_STORE',
   UPDATE_CACHE: 'UPDATE_CACHE',
   RESET_CACHE: 'RESET_CACHE',
   RESET_REDUX: 'RESET_REDUX',
   RESET_USER_REDUX: 'RESET_USER_REDUX'
}