export const EpicTypes = {

   // login
   VCARE_LOGIN: '[EPIC] - VCARE_LOGIN',
   LOGOUT: '[EPIC] - LOGOUT',
   /////////////////////
   // User settings
   ////////////////////
   POST_USER_SETTINGS_SIGN_IN: '[EPIC] - POST_USER_SETTINGS_SIGN_IN',
   POST_USER_SETTINGS_SIGN_UP: '[EPIC] - POST_USER_SETTINGS_SIGN_UP',
   POST_USER_SETTINGS_FORGOT_PASSWORD: '[EPIC] - POST_USER_SETTINGS_FORGOT_PASSWORD',
   POST_USER_SETTINGS_RESET_PASSWORD: '[EPIC] - POST_USER_SETTINGS_RESET_PASSWORD',
   POST_USER_SETTINGS_DETAILS_UPDATE: '[EPIC] - POST_USER_SETTINGS_DETAILS_UPDATE',
   POST_USER_SETTINGS_DETAILS_PHOTO_UPLOAD: '[EPIC] - POST_USER_SETTINGS_DETAILS_PHOTO_UPLOAD',
   POST_USER_SETTINGS_SIGN_OUT: '[EPIC] - POST_USER_SETTINGS_SIGN_OUT',
   POST_USER_SETTINGS_PORTFOLIO_ID: '[EPIC] - POST_USER_SETTINGS_PORTFOLIO_ID',
   POST_USER_ADD_PORTFOLIO: '[EPIC] - POST_USER_ADD_PORTFOLIO',
   POST_USER_DELETE_PORTFOLIO: '[EPIC] - POST_USER_DELETE_PORTFOLIO',
   POST_USER_EDIT_PORTFOLIO: '[EPIC] - POST_USER_EDIT_PORTFOLIO',
   FETCH_USER_SETTINGS_SIGN_IN:'[EPIC] - FETCH_USER_SETTINGS_SIGN_IN',
   FETCH_USER_SETTINGS_ACCOUNT_DATA:'[EPIC] - FETCH_USER_SETTINGS_ACCOUNT_DATA',
   FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS:'[EPIC] - FETCH_USER_SETTINGS_ACCOUNT_PORTFOLIOS',
   
   ///////////////////
   // Website redux
   ////////////////////
   FETCH_WEBSITE_YOUTUBE_CONTEN: '[EPIC] - FETCH_WEBSITE_YOUTUBE_CONTEN',
   FETCH_WEBSITE_IS_AUTHENTICATED: '[EPIC] - FETCH_WEBSITE_IS_AUTHENTICATED',
   FETCH_WEBSITE_TIME_FRAMES: '[EPIC] - FETCH_WEBSITE_TIME_FRAMES',
   FETCH_WEBSITE_COUNTORY_CODES: '[EPIC] - FETCH_WEBSITE_COUNTORY_CODES',
   FETCH_WEBSITE_NZX_LATEST_PRICE: '[EPIC] - FETCH_WEBSITE_NZX_LATEST_PRICE',
   FETCH_WEBSITE_ASX_LATEST_PRICE: '[EPIC] - FETCH_WEBSITE_ASX_LATEST_PRICE',
   FETCH_WEBSITE_SET_ALL_STOCK_CODE_NAME_MARKET: '[EPIC] - FETCH_WEBSITE_SET_ALL_STOCK_CODE_NAME_MARKET',
   FETCH_WEBSITE_SET_STOCK_ANNOUNCEMENTS: '[EPIC] - FETCH_WEBSITE_SET_STOCK_ANNOUNCEMENTS',

   ///////////////////
   // Service
   ////////////////////
   FETCH_ALL_DATA_FROM_THE_SERVER: '[EPIC] - FETCH_ALL_DATA_FROM_THE_SERVER',
   FETCH_ACTIVITIES_FOR_ME: '[EPIC] - FETCH_ACTIVITIES_FOR_ME',
   CLEAR_ALL_STATE: '[EPIC] - CLEAR_ALL_STATE',

   ///////////////////
   // UI
   ////////////////////
   UI_EPIC_STOP_LOADING: '[EPIC] - UI_EPIC_STOP_LOADING',

   /////////////////
   // Dividends
   ///////////////
   FETCH_DIVIDEND_USER_DATA:'[EPIC] - FETCH_DIVIDEND_USER_DATA',
   FETCH_DIVIDEND_SINGLE_HISTORY:'[EPIC] - FETCH_DIVIDEND_SINGLE_HISTORY',
   FETCH_DIVIDEND_SINGLE_VALUE_HISTORY:'[EPIC] - FETCH_DIVIDEND_SINGLE_VALUE_HISTORY',
   FETCH_DIVIDEND_UP_COMMING_NZ:'[EPIC] - FETCH_DIVIDEND_UP_COMMING_NZ',
   FETCH_DIVIDEND_UP_COMMING_AS:'[EPIC] - FETCH_DIVIDEND_UP_COMMING_AS',
   FETCH_DIVIDEND_USER_UP_COMMING_NZ:'[EPIC] - FETCH_DIVIDEND_USER_UP_COMMING_NZ',
   FETCH_DIVIDEND_USER_UP_COMMING_AS:'[EPIC] - FETCH_DIVIDEND_USER_UP_COMMING_AS',
   FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES:'[EPIC] - FETCH_DIVIDEND_NZ_CALENDAR_CODES_DATES',
   FETCH_DIVIDEND_AS_CALENDAR_CODES_DATES:'[EPIC] - FETCH_DIVIDEND_AS_CALENDAR_CODES_DATES',
   
   //////////////////
   // User Stocks
   /////////////////
   FETCH_USER_STOCKS_DATA:'[EPIC] - FETCH_USER_STOCKS_DATA',
   FETCH_USER_STOCKS_ACTIONS:'[EPIC] - FETCH_USER_STOCKS_ACTIONS',
   FETCH_USER_STOCKS_HISTORY_DATA:'[EPIC] - FETCH_USER_STOCKS_HISTORY_DATA',
   FETCH_BROKAGE_DATA:'[EPIC] - FETCH_BROKAGE_DATA',
   POST_USER_STOCKS_ADD_STOCK:'[EPIC] - POST_USER_STOCKS_ADD_STOCK',
   POST_USER_STOCKS_EDIT_STOCK:'[EPIC] - POST_USER_STOCKS_EDIT_STOCK',
   POST_USER_STOCKS_REMOVE_STOCK:'[EPIC] - POST_USER_STOCKS_REMOVE_STOCK',
   POST_USER_STOCKS_DIVIDEND_UPDATE:'[EPIC] - POST_USER_STOCKS_DIVIDEND_UPDATE',


   ////////////////
   // Crypto
   ///////////////
   FETCH_CRYPTO_USER_LIST:'[EPIC] - FETCH_CRYPTO_USER_LIST',
   FETCH_CRYPTO_META_DATA:'[EPIC] - FETCH_CRYPTO_META_DATA',
   FETCH_CRYPTO_QUOTE_DATA:'[EPIC] - FETCH_CRYPTO_QUOTE_DATA',

   ////////////////
   // Warrants
   ///////////////
   FETCH_ALL_USER_WARRANT_DATA:'[EPIC] - FETCH_ALL_USER_WARRANT_DATA',
   FETCH_WARRANT_HISTORY_DATA_FOR:'[EPIC] - FETCH_WARRANT_HISTORY_DATA_FOR',
   POST_USER_WARRANT_ADD:'[EPIC] - POST_USER_WARRANT_ADD',
   POST_USER_WARRANT_EDIT:'[EPIC] - POST_USER_WARRANT_EDIT',
   POST_USER_WARRANT_EXCUTE_BUY:'[EPIC] - POST_USER_WARRANT_EXCUTE_BUY',
   
   ///////////////////
   // User Goals
   ////////////////////
   FETCH_GOAL_USER_LIST:'[EPIC] - FETCH_GOAL_USER_LIST',
   POST_GOAL_USER_ADD_GOAL: '[EPIC] - POST_GOAL_USER_ADD_GOAL', 
   POST_GOAL_USER_DELETE_GOAL: '[EPIC] - POST_GOAL_USER_DELETE_GOAL', 
   POST_GOAL_USER_EDIT_GOAL: '[EPIC] - POST_GOAL_USER_EDIT_GOAL', 

   //////////////////////
   // Calculations
   ///////////////////////
   FETCH_CALCULATION_DIVIDENS_HISTORY:'[EPIC] - FETCH_CALCULATION_DIVIDENS_HISTORY',
   
   //////////////////////////////////////////
   // Reports
   //////////////////////////////////////////
   FETCH_REPORT_STOCKS_HISTORY_DATA:'[EPIC] - FETCH_REPORT_STOCKS_HISTORY_DATA',

   /////////////////////////////////////////
   // Download History
   /////////////////////////////////////////
   FETCH_STOCK_HISTORY_FOR:'[EPIC] - FETCH_STOCK_HISTORY_FOR',
   
}
