import { ActionTypes } from '../ActionTypes';
import rLensProp from 'ramda/src/lensProp';
import rSet from 'ramda/src/set';
//import rClone from 'ramda/src/clone';
/*import _get from 'lodash/get';
import _set from 'lodash/set';*/


export const userStocksState = {
    userStocksDashData: [],
    userStocksRecentActions: [],
    userNZStocksData: [],
    userASStocksData: [],
    userStockValue: 0,
    userStockInvestedValue: 0,
    userNZStockValue: 0,
    userAUStockValue: 0,
    userWarrantData: [],
    userWarrantHistoryData: [],
    userStockNotifcations: [],
    userStockHistory: [],
    brokageData: [],
}

const userStocksDashDataProp = rLensProp('userStocksDashData');
const userStocksRecentActionsProp = rLensProp('userStocksRecentActions');
const userNZStocksDataProp = rLensProp('userNZStocksData');
const userASStocksDataProp = rLensProp('userASStocksData');
const userStockValueProp = rLensProp('userStockValue');
const userStockInvestedValueProp = rLensProp('userStockInvestedValue');
const userNZStockValueProp = rLensProp('userNZStockValue');
const userAUStockValueProp = rLensProp('userAUStockValue');
const userStockNotifcationsProp = rLensProp('userStockNotifcations');
const userStockHistoryProp = rLensProp('userStockHistory');
const userWarrantDataProp = rLensProp('userWarrantData');
const userWarrantHistoryDataProp = rLensProp('userWarrantHistoryData');
const brokageDataProp = rLensProp('brokageData');

/*
https://levelup.gitconnected.com/react-redux-hooks-useselector-and-usedispatch-f7d8c7f75cdd
*/

const userStocksReducer = (state = userStocksState, action) => {
    switch (action.type) {
        case ActionTypes.ASSIGN_BROKAGE_DATA:
            return rSet(
                brokageDataProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_STOCKS_NOTIFCATIONS:
            return rSet(
                userStockNotifcationsProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_STOCK_HISORY_DATA:
            return rSet(
                userStockHistoryProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_STOCKS_RECENT_ACTIONS_DATA:
            return rSet(
                userStocksRecentActionsProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_STOCKS_VALUE_DATA:
            return rSet(
                userStockValueProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_STOCKS_INVESTED_VALUE_DATA:
            return rSet(
                userStockInvestedValueProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_NZ_STOCKS_VALUE_DATA:
            return rSet(
                userNZStockValueProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_AU_STOCKS_VALUE_DATA:
            return rSet(
                userAUStockValueProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_DASH_STOCKS_DATA:
            return rSet(
                userStocksDashDataProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_NZ_STOCKS_DATA:
            return rSet(
                userNZStocksDataProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_AS_STOCKS_DATA:
            return rSet(
                userASStocksDataProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_WARRANT_DATA:
            return rSet(
                userWarrantDataProp,
                action.payload,
                state
            )
        case ActionTypes.ASSIGN_USER_WARRANT_HISORY_DATA:
            return rSet(
                userWarrantHistoryDataProp,
                action.payload,
                state
            )
        case ActionTypes.RESET_USER_REDUX:
        case ActionTypes.RESET_REDUX:
            console.log('User Stocks Resets to defults');
            return userStocksState;
        default:
            return state;
    }
}

export default userStocksReducer;